/**
 * removes premium or preview from path
 * @param path path to slice premium or preview from
 * @returns path without premium and preview
 */
export function premiumPrefixSlice(path: string) {
    if (path.startsWith(premiumPath)) {
        path = path.slice(premiumPath.length)
    }

    if (path.startsWith(previewPath)) {
        path = path.slice(previewPath.length)
    }

    if (!path) path = "/"

    return path
}

/**
 * checks if path is public
 * @param path path to check
 * @returns true if path is public
 */
export function isPublicPath(path: string) {
    return (
        !path.startsWith(premiumPath) &&
        !path.startsWith(previewPath) &&
        !path.startsWith(adminPath) &&
        !path.startsWith(assistantPath)
    )
}

/**
 * checks if path is premium
 * @param path path to check
 * @returns true if path is premium
 */
export function isPremiumPath(path: string) {
    return (
        path.startsWith(premiumPath) &&
        !path.startsWith(previewPath) &&
        !path.startsWith(adminPath) &&
        !path.startsWith(assistantPath)
    )
}

/**
 * checks if path is preview
 * @param path path to check
 * @returns true if path is preview
 */
export function isPreviewPath(path: string) {
    return (
        path.startsWith(previewPath) &&
        !path.startsWith(premiumPath) &&
        !path.startsWith(adminPath) &&
        !path.startsWith(assistantPath)
    )
}

/**
 * checks if path is admin
 * @param path path to check
 * @returns true if path is admin
 */
export function isAdminPath(path: string) {
    return (
        path.startsWith(adminPath) &&
        !path.startsWith(premiumPath) &&
        !path.startsWith(previewPath) &&
        !path.startsWith(assistantPath)
    )
}

/**
 * checks if path is assistant
 * @param path path to check
 * @returns true if path is assistant
 */
export function isAssistantPath(path: string) {
    return (
        path.startsWith(assistantPath) &&
        !path.startsWith(premiumPath) &&
        !path.startsWith(previewPath) &&
        !path.startsWith(adminPath)
    )
}

export const premiumPath = "/premium"
export const previewPath = "/preview"
export const adminPath = "/admin"
export const assistantPath = "/assistant"
