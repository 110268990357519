// eslint-disable-next-line no-restricted-imports
import { BareFetcher, Key } from "swr"
// eslint-disable-next-line no-restricted-imports
import useSWRImmutable from "swr/immutable"

function useQueryData<TData, TError>(key: Key,
    fetcher: BareFetcher<TData>,
    enabled = true,
    refreshInterval: number | undefined = undefined,
    onSuccess: (data: TData, key: string) => void = () => { }) {
    const { data, isLoading, error, mutate } = useSWRImmutable<TData, TError>(enabled ? key : null, fetcher, {
        refreshInterval,
        onSuccess
    })

    return { data, isLoading, error, mutate }
}

export default useQueryData
