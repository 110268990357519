import { Icon } from "components/Icon";
import Notification from "components/ProfilePopover/User/Notification";
import { ProfileTabType, StyledDefaultHeader } from "components/ProfilePopover/User/ProfileUser";
import { t } from "locales";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useGetNotifications, useMarkNotificationAsRead } from "data/contentData/hooks/notification.hooks";
import { snackbarMessage } from "components/Snackbar/utils";
export function NotificationsTab({
  changeTab
}: {
  changeTab: (tab: ProfileTabType) => () => void;
}) {
  const {
    mutate: markAsRead
  } = useMarkNotificationAsRead();
  const {
    isLoading,
    data: notifications,
    error
  } = useGetNotifications();
  const handleMarkAsRead = (id: number) => () => {
    markAsRead({
      id
    });
    snackbarMessage({
      open: true,
      message: t["notifications"]["notification-was-removed"],
      color: "info"
    });
  };
  if (isLoading) {
    return <List>
                {[...Array(3).keys()].map(id => <ListItem divider key={id}>
                        <ListItemText primary={<Skeleton height={24} width={150} />} secondary={<Skeleton height={20} width={65} />} />
                    </ListItem>)}
            </List>;
  }
  const noNotifications = !error && (!notifications || !notifications.length);
  return <>
            <StyledDefaultHeader data-sentry-element="StyledDefaultHeader" data-sentry-source-file="NotificationsTab.tsx">
                <IconButton size="small" onClick={changeTab("default")} data-sentry-element="IconButton" data-sentry-source-file="NotificationsTab.tsx">
                    <Icon name="arrowBack" fontSize="small" data-sentry-element="Icon" data-sentry-source-file="NotificationsTab.tsx" />
                </IconButton>
                {t.profile.notifications}
            </StyledDefaultHeader>
            {noNotifications ? <Typography variant="caption">
                    {t["notifications"]["no-new-notifications"]}
                </Typography> : <List>
                    {notifications?.map((notification: any) => <Notification key={notification.id} {...notification} onMarkAsRead={handleMarkAsRead(notification.id)} />)}
                </List>}
        </>;
}