import {
    ISearchResult,
    ISearchSuggestionGroup,
    getLatestArticles,
    getSearch,
    getSearchAutocomplete,
    getSearchSuggestions
} from "data/contentData/api/search"
import useQueryData from "hooks/useQueryData"

enum SearchCacheKeys {
    SEARCH = "search",
    SUGGESTIONS = "search-suggestions",
    FACETS = "search-facets",
    LATEST_ARTICLES = "latest-articles"
}

export interface ISearchSuggestionsAndAutocomlete {
    suggestionGroups: ISearchSuggestionGroup[]
    autocomplete: string[]
}

export function useGetSearchSuggestions(q: string, enabled: boolean) {
    return useQueryData<ISearchSuggestionsAndAutocomlete, Error>(
        [SearchCacheKeys.SUGGESTIONS, q],
        async () => {
            const suggestionGroups = await getSearchSuggestions(q)
            const autocomplete = await getSearchAutocomplete(q)

            return {
                suggestionGroups,
                autocomplete
            }
        },
        enabled
    )
}

interface ISearchParams {
    q?: string | null | undefined
    templateSubGroup?: string | null | undefined
    limit?: number
    skip?: number
    sortType?: number
    fuzzy?: boolean
    editorMode?: boolean
    bypassCache?: boolean
    enabled?: boolean
}

export function useGetSearch({
    q,
    templateSubGroup,
    limit,
    skip,
    sortType,
    fuzzy,
    editorMode,
    bypassCache,
    enabled
}: ISearchParams) {
    // Custom increased staleTime to avoid multiple requests. Facet count
    // from initial search (without type filter) should persist when filtering
    return useQueryData<ISearchResult, Error>(
        [
            SearchCacheKeys.SEARCH,
            q,
            limit,
            skip,
            sortType,
            fuzzy,
            templateSubGroup,
            editorMode,
            bypassCache
        ],
        async () => {
            return await getSearch(
                q,
                templateSubGroup,
                limit,
                skip,
                sortType,
                fuzzy,
                editorMode,
                bypassCache
            )
        },
        enabled ?? true
    )
}

export type LatestArticleType = {
    timestamp: Date
    title: string
    url: string
}

export function useGetLatestArticles(
    templateGroup: string,
    limit: number,
    includeNew: boolean,
    includeUpdated: boolean,
    includeRevised: boolean,
    enabled?: boolean,
    bypassCache?: boolean
) {
    return useQueryData<LatestArticleType[], Error>(
        [SearchCacheKeys.LATEST_ARTICLES],
        async () =>
            await getLatestArticles(
                templateGroup,
                limit,
                includeNew,
                includeUpdated,
                includeRevised,
                bypassCache
            ),
        enabled ?? true
    )
}
