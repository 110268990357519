import { config } from "config"
import { Session } from "next-auth"
import { signOut } from "next-auth/react"
import { v4 as generateId } from "uuid"

import { signIn } from "auth/signIn"
import { getSessionId } from "lib/http/http"
import useQueryData from "./useQueryData"

let handled = false
let lock = false
export interface IUseUser extends Partial<Session> {
    authenticated: boolean
    isLoading: boolean
}

export default function useUser(): IUseUser {
    const {
        data: session,
        isLoading,
        mutate: refetch
    } = useQueryData<Session | null, Error>(
        ["session"],
        fetchSession,
        true,
        undefined,
        () => lock = false
    )

    const authenticated = isAuthenticated(isLoading, session)

    if (session?.error) {
        if (!handled) {
            handled = true
            switch (session.error) {
                case "concurrency": {
                    const path = config.rewrites["/concurrent-logins"]
                    const userConcurrency = session.user?.concurrency
                    // Redirect to page that explains the reason
                    signOut({ callbackUrl: `${path}?allowed=${userConcurrency}` })
                    break
                }
                case "logged-out":
                    // The user has been logged out through a front-channel logout, log them out of the app as well
                    signOut({ redirect: false })
                    break
                case "restored": // The server has been restarted, we try to log the user in, silently, if possible.
                    signIn()
                    break
                case "refresh-access-token": // The refresh token did not work, try logging the user in, silently, if possible.
                    signIn()
                    break
                case "jwt":
                    signOut({ callbackUrl: "/error?code=auth" })
                default:
                    break
            }
        }

        session.user = undefined
    } else {
        if (shouldRefetchSession(isLoading, session) && !lock) {
            lock = true
            refetch()
        }
    }

    return {
        ...(session ?? {}),
        authenticated,
        isLoading
    }
}

function shouldRefetchSession(isFetching: boolean, session?: Session | null) {
    return (
        !isFetching &&
        session &&
        session.accessToken &&
        session.accessTokenExpires < Date.now()
    )
}

function isAuthenticated(loading: boolean, session?: Session | null) {
    return Boolean(
        !loading &&
        session &&
        session.user &&
        session.accessToken &&
        session.accessTokenExpires > Date.now() &&
        !session.error
    )
}

/**
 * Normal fetch with correlation and session id headers.
 * @param input
 * @param init
 * @returns
 */
function fetchWithTrace(input: RequestInfo, init?: RequestInit | undefined) {
    return fetch(input, {
        ...init,
        headers: {
            ...init?.headers,
            "X-Session-ID": getSessionId(),
            "X-Correlation-ID": generateId()
        }
    })
}

/**
 * Fetches session from `/api/auth/session`, parses its JSON response, and returns it. If there is no session, it returns `null`
 * @returns
 */
async function fetchSession(): Promise<Session | null> {
    const res = await fetchWithTrace("/api/auth/session")
    const session = await res.json()
    if (Object.keys(session).length) {
        return session
    }
    return null
}
