import { captureException } from "@sentry/nextjs"
import { AccessTokenResponseType, AuthConfigType, IProduct } from "auth"
import { config } from "config"
import { JWT } from "next-auth/jwt"
import KeycloakProvider, { KeycloakProfile } from "next-auth/providers/keycloak"

import { getActiveCDSOrders } from "data/prolexShop/api/user"

const { auth } = config

/**
 * Access token url for server side requests
 */
const accessTokenUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/realms/poland/connect/token`

/**
 * Edit profile url - used to navigate user to edit profile page
 */
const editProfileUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/account/poland`

/**
 * End session url - used to log user out from identity server
 */
const endSessionUrl = `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/realms/poland/protocol/openid-connect/logout`

interface KeycloakProfileWithRoles extends KeycloakProfile {
    resource_access?: {
        "cds-ui"?: {
            roles?: string[]
        }
    }
}

/**
 * IdentityServer4 provider setup for next-auth
 */
const provider = KeycloakProvider({
    clientId: auth.clientId,
    clientSecret: "",
    issuer: `${process.env.NEXT_PUBLIC_AUTH_ISSUER}/realms/poland`,
    async profile(p, tokens) {
        const profile = p as KeycloakProfileWithRoles
        const accessToken = tokens.access_token

        const roles: string[] = profile.resource_access?.["cds-ui"]?.roles ?? []
        try {
            // Check prolex shop for active subscription
            if (accessToken !== undefined) {
                const order = await getActiveCDSOrders(accessToken)
                const hasActiveSubscription =
                    order !== null && order.data.length > 0
                if (hasActiveSubscription) {
                    roles.push(...auth.roles.subscriber)
                }
            }
        } catch (error) {
            captureException(error)
        }

        return {
            id: profile.sub,
            guid: profile.sub,
            parentId: "",
            email: profile.email,
            name: profile.name,
            concurrency: auth.defaultMaxConcurrentLogins,
            roles,
            products: []
        }
    },
    checks: ["pkce"]
})

/**
 * Get JWT to authenticate requests from frontend-backend to supported services
 * @returns access token and expiration time
 */
async function getServerSideAccessToken(): Promise<AccessTokenResponseType> {
    const url = "https://login.microsoftonline.com/nhi.no/oauth2/v2.0/token"

    const response = await fetch(url, {
        body: new URLSearchParams({
            client_id: process.env.ENTRA_ID_CLIENT_ID,
            client_secret: process.env.ENTRA_ID_SERVER_SIDE_SECRET,
            tenant_id: process.env.ENTRA_ID_TENANT_ID,
            grant_type: "client_credentials",
            scope: process.env.ENTRA_ID_SERVER_SIDE_SCOPE
        }),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "post"
    })

    const accessToken: AccessTokenResponseType = await response.json()

    if (response.ok) {
        return {
            ...accessToken,
            // Give a 10 sec buffer
            expires_in: accessToken.expires_in - 10
        }
    }

    throw {
        message: "AccessTokenError",
        accessToken
    }
}

/**
 * Get roles for the user
 * @param token JWT
 * @returns list of roles
 */
async function getRoles(token: JWT) {
    try {
        const roles = token.user?.roles ?? []

        // Check prolex shop for active subscription
        if (Boolean(token.accessToken)) {
            const order = await getActiveCDSOrders(token.accessToken)
            const hasActiveSubscription =
                order !== null && order.data.length > 0
            if (hasActiveSubscription) {
                auth.roles.subscriber.forEach(role => {
                    if (!roles.includes(role)) {
                        roles.push(role)
                    }
                })
            }
        }

        return roles
    } catch (error) {
        captureException(error)
    }
}

/**
 * Get active CDS products for user
 * @param token JWT
 * @returns list of active products
 */
async function getProducts(token: JWT): Promise<IProduct[]> {
    if (!token.accessToken) {
        return []
    }

    const activeOrders = await getActiveCDSOrders(token.accessToken)
    if (!activeOrders || activeOrders.data.length === 0) {
        return []
    }

    const products = activeOrders.data.map(order => ({
        id: order.id,
        name: order.product.name,
        productId: order.product.id,
        start: order.created_at
    }))

    return products
}

export const keycloakConfig: AuthConfigType = {
    provider,
    accessTokenUrl,
    editProfileUrl,
    endSessionUrl,
    getServerSideAccessToken,
    getRoles,
    getProducts
}
