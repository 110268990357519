import {
    IArticleUpdate,
    IFooterCard,
    IMegamenuNode,
    IPage,
    getFooterCards,
    getMegamenu,
    getPage,
    getUpdates
} from "data/contentData/api/content"
import useQueryData from "hooks/useQueryData"

enum ContentCacheKeys {
    PAGES = "pages",
    PAGES_PROTECTED = "pages-protected",
    FOOTER = "footer",
    ARTICLE_UPDATES = "article-updates",
    MEGAMENU = "megamenu"
}

/**
 * Get public section of page.
 * @param options
 * @returns
 */
export function useGetPagePublic(
    path: string,
    enabled?: boolean,
) {
    return useQueryData<IPage | null, Error>(
        [ContentCacheKeys.PAGES, path],
        async () => await getPage(path),
        enabled ?? true
    )
}

export function useGetArticleUpdates(
    skip: number,
    take: number,
    onlyCurrent: boolean,
    onlyImportant: boolean
) {
    return useQueryData<IArticleUpdate[], Error>(
        [ContentCacheKeys.ARTICLE_UPDATES, skip],
        async () => await getUpdates(skip, take, onlyImportant, onlyCurrent)
    )
}

export function useGetMegamenu(isGetMegamenuEnabled: boolean) {
    return useQueryData<IMegamenuNode, Error>(
        ContentCacheKeys.MEGAMENU,
        getMegamenu,
        isGetMegamenuEnabled
    )
}

export function useGetFooterCards(enabled: boolean) {
    return useQueryData<IFooterCard[], Error>(
        ContentCacheKeys.FOOTER,
        async () => await getFooterCards(),
        enabled
    )
}
