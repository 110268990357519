import NodeDrawer from "components/Drawer/NodeDrawer";
import { Icon } from "components/Icon";
import MegamenuPopover from "components/Megamenu/MegamenuPopover";
import { t } from "locales";
import { MouseEvent, useEffect, useState } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Hidden from "@mui/material/Hidden";
import { styled } from "@mui/material/styles";
import { IMegamenuNode } from "data/contentData/api/content";
import { useGetMegamenu } from "data/contentData/hooks/content.hooks";

/**
 * Handbook   /
 * Volumes    /kliniske-kapitler          Volume[] makes up the left side
 * Topics     /kliniske-kapitler/akutt    Topics[] makes up the right side
 */
export default function Megamenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isGetMegamenuEnabled, setIsGetMegamenuEnabled] = useState(false);
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (open) {
      setIsGetMegamenuEnabled(true);
    }
  }, [open]);
  const {
    data
  } = useGetMegamenu(isGetMegamenuEnabled);
  const aside = megamenuAside(data);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(open ? null : event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  return <>
            <MegamenuToggler aria-label={t.megamenu[open ? "close" : "open"]} onClick={handleClick} data-sentry-element="MegamenuToggler" data-sentry-source-file="Megamenu.tsx">
                {open ? <Icon name="close" /> : <Icon name="menu" />}
            </MegamenuToggler>
            {data ? <>
                    <Hidden mdDown>
                        <MegamenuPopover anchorEl={anchorEl} handleClose={handleClose} nodes={data.nodes} open={open} />
                    </Hidden>
                    <Hidden mdUp>
                        <NodeDrawer linksAtDepth={3} node={aside} onClose={handleClose} open={open} />
                    </Hidden>
                </> : null}
        </>;
}
const MegamenuToggler = styled(ButtonBase)(({
  theme
}) => ({
  backgroundColor: theme.palette.cds[700],
  width: 48,
  height: 48,
  "&:focus": {
    backgroundColor: theme.palette.cds[800],
    boxShadow: `inset 0 0 0 3px ${theme.palette.grey.white}`,
    outline: "none"
  }
}));

// REVIEW: obscure type-requirements in Aside
function megamenuAside(megamenu: IMegamenuNode | undefined) {
  if (!megamenu || !megamenu.nodes) {
    return {
      name: "",
      url: "",
      nodes: []
    };
  }
  return {
    name: megamenu.name,
    url: "",
    nodes: megamenu.nodes.map(volume => {
      const {
        name,
        url,
        type,
        icon,
        viewType,
        nodes
      } = volume;
      const topics = nodes?.map(topic => {
        // const { name, url, nodes } = topic
        const topicNodes = topic.nodes?.map(node => ({
          name: node.name,
          url: node.url
        }));
        return {
          name: topic.name,
          url: topic.url,
          // If `viewType` is defined, we don't need this layer
          ...(topicNodes && !viewType ? {
            nodes: topicNodes
          } : {})
        };
      });
      return {
        name,
        url: url,
        ...(topics ? {
          nodes: topics
        } : {}),
        ...(icon ? {
          icon
        } : {}),
        ...(type && type !== "List" ? {
          type
        } : {}),
        ...(viewType ? {
          viewType
        } : {})
      };
    })
  };
}