import { isSessionStorageSupported } from "../storage/is-sessionstorage-supported"
import { captureMessage } from "@sentry/nextjs"
import { v4 } from "uuid"

/** Returns either a newly set or an already saved id from `sessionStorage`. */
export function getSessionId() {
    if (!isSessionStorageSupported()) return v4()
    const id = sessionStorage.getItem("X-Session-ID")
    if (id) return id

    const newId = v4()
    sessionStorage.setItem("X-Session-ID", newId)

    return newId
}

export class HttpError extends Error {
    response: Response
    constructor(message: string, response: Response) {
        super(message)
        this.name = "HttpError"
        this.message = message
        this.response = response
    }
}

interface IHTTPOptions {
    token?: string | null | undefined
    method?: string
    body?: any
    customHeaders?: { [key: string]: string } | undefined
}

interface IHTTPResponse<T> {
    response: Response
    data: T | null
}

const defaultOptions: IHTTPOptions = {
    method: "GET"
}

export async function http<T>(
    url: string,
    options?: IHTTPOptions,
    cache?: RequestCache
): Promise<IHTTPResponse<T>> {
    const { token, method, body, customHeaders } = {
        ...defaultOptions,
        ...options
    }
    const sessionId = typeof window !== "undefined" ? getSessionId() : null
    const correlationId = v4()
    let init: RequestInit = {
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "X-Correlation-ID": correlationId,
            ...(sessionId ? { "X-Session-ID": sessionId } : undefined),
            ...(token ? { Authorization: `Bearer ${token}` } : undefined),
            ...customHeaders
        },
        method,
        body
    }

    if (cache) {
        init = {
            ...init,
            cache: cache
        }
    }

    const response = await fetch(url, init)

    let data: T | null = null
    if (response.ok) {
        if (response.status === 204) {
            data = null
        }

        const contentType = response.headers.get("content-type") ?? ""
        if (contentType.includes("application/json")) {
            data = (await response.json()) as T
        } else if (response.status !== 204) {
            const text = await response.text()
            const message = JSON.stringify({
                message: "Non-JSON response, investigate endpoint with params",
                url,
                contentType,
                text
            })
            captureMessage(message, { level: "info" })
        }
    } else {
        throw new HttpError(
            `Network response was not ok, url: ${url}`,
            response
        )
    }

    return { response, data }
}

export async function get<T>(
    url: string,
    token?: string | null | undefined,
    customHeaders?: { [key: string]: string } | undefined,
    cache?: RequestCache
): Promise<IHTTPResponse<T>> {
    return http<T>(url, { token, customHeaders }, cache)
}

export async function post<T>(
    url: string,
    body: string,
    token?: string | null | undefined,
    customHeaders?: { [key: string]: string } | undefined
): Promise<IHTTPResponse<T>> {
    return http<T>(url, { token, method: "POST", body, customHeaders })
}

export async function put<T>(
    url: string,
    body: string,
    token?: string | null | undefined,
    customHeaders?: { [key: string]: string } | undefined
): Promise<IHTTPResponse<T>> {
    return http<T>(url, { token, method: "PUT", body, customHeaders })
}
