import { config } from "config"
import useUser from "hooks/useUser"

import {
    IProfile,
    getProfile,
    updateProfile
} from "data/customerManager/api/profile"
import useMutateData from "hooks/useMutateData"
import useQueryData from "hooks/useQueryData"

enum ProfileCacheKeys {
    PROFILE = "profile"
}

/**
 * Get profile of the authenticated user
 * @param options React Query options
 * @returns Profile of the authenticated user
 */

export function useGetProfile() {
    const { authenticated, accessToken, isLoading, user } = useUser()

    const enabled =
        authenticated && !isLoading && config.features.enableConfirmUserDetails

    return useQueryData<IProfile | null, Error>(
        enabled ? [ProfileCacheKeys.PROFILE, user?.guid] : null,
        async () => await getProfile(accessToken)
    )
}

/**
 * Update profile of the authenticated user
 * @returns Mutation function to update profile
 */
export function useUpdateProfile() {
    const { mutate } = useGetProfile()
    return useMutateData(
        updateProfile,
        () => {
            mutate()
        },
        () => {
            mutate()
        }
    )
}
