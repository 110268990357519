import useUser from "hooks/useUser"
import { useCallback, useMemo } from "react"

import {
    FavoriteSorterType,
    IFavorite,
    IUserSettings,
    getUserSettings,
    updateUserSettings
} from "data/contentData/api/user"
import useQueryData from "hooks/useQueryData"
import useMutateData from "hooks/useMutateData"

enum UserCacheKeys {
    USER_SETTINGS = "user-settings"
}

export function useGetUserSettings() {
    const { authenticated, accessToken, isLoading } = useUser()

    return useQueryData<IUserSettings | undefined, Error>(
        [UserCacheKeys.USER_SETTINGS],
        () => getUserSettings(accessToken),
        authenticated && !isLoading
    )
}

export function useUpdateUserSettings() {
    const { mutate: mutauserSettings } = useGetUserSettings()
    return useMutateData(
        updateUserSettings,
        () => {
            mutauserSettings()
        },
        () => {
            mutauserSettings()
        }
    )
}

type DrawerSorterFn = (a: IFavorite, b: IFavorite) => number

const sorters: Record<FavoriteSorterType, DrawerSorterFn> = {
    abc: (a, b) => a.name.localeCompare(b.name),
    time: (a, b) =>
        new Date(b.timestamp as Date).valueOf() -
        new Date(a.timestamp as Date).valueOf(),
    template: (a, b) => a.template.localeCompare(b.template)
}

/**
 * Hook to add current page to favorites,
 * or remove if already added
 */
export function useFavorites(id?: string): {
    favorite?: IFavorite
    favorites: IFavorite[]
    toggle: (favorite?: IFavorite) => void
    add: (favorite: IFavorite) => void
    remove: (id: string) => void
    sort: (sortBy: FavoriteSorterType) => void
    sortBy: FavoriteSorterType
    loading: boolean
} {
    const { data: userSettings } = useGetUserSettings()
    const { mutate: mutateUpdateUserSettings, isLoading } = useUpdateUserSettings()
    const { accessToken } = useUser()

    const sortBySetting = userSettings?.favorites?.sortBy ?? "abc"
    const list = useMemo(
        () => userSettings?.favorites?.list ?? [],
        [userSettings?.favorites?.list]
    )
    const favorites = list.sort(sorters[sortBySetting])

    const remove = useCallback(
        (url: string) => {
            const newUserSettings = {
                ...userSettings,
                favorites: {
                    sortBy: sortBySetting,
                    list: list.filter(p => p.url !== url)
                }
            }

            mutateUpdateUserSettings(newUserSettings, accessToken)
        },
        [
            accessToken,
            list,
            mutateUpdateUserSettings,
            sortBySetting,
            userSettings
        ]
    )

    const add = useCallback(
        (favorite: IFavorite) => {
            if (!favorite.timestamp) {
                favorite.timestamp = new Date()
            }

            const newUserSettings = {
                ...userSettings,
                favorites: {
                    sortBy: sortBySetting,
                    list: [...list, favorite]
                }
            }
            mutateUpdateUserSettings(newUserSettings, accessToken)
        },
        [accessToken, list, mutateUpdateUserSettings, sortBySetting, userSettings]
    )

    const getFavorite = useCallback(
        (passedId: string) => {
            return list.find(f => f.url === passedId)
        },
        [list]
    )

    const toggle = useCallback(
        (favorite?: IFavorite) => {
            if (!favorite?.name) {
                return
            }
            const added = getFavorite(favorite.url)
            if (added) {
                remove(favorite.url)
            } else {
                add(favorite)
            }
        },
        [add, getFavorite, remove]
    )

    const sort = useCallback(
        (sortBy: FavoriteSorterType) => {
            const newUserSettings = {
                ...userSettings,
                favorites: {
                    list,
                    sortBy: sortBy
                }
            }
            mutateUpdateUserSettings(newUserSettings, accessToken)
        },
        [accessToken, list, mutateUpdateUserSettings, userSettings]
    )

    return {
        add,
        favorite: id ? getFavorite(id) : undefined,
        favorites,
        loading: isLoading,
        remove,
        sort,
        sortBy: sortBySetting,
        toggle
    }
}
