import useQueryData from "hooks/useQueryData"

import {
    IDictionaryWord,
    getDictionaryTerm,
    getRegions
} from "data/contentData/api/config"

export enum ConfigCacheKeys {
    BANNERS = "banners",
    REGIONS = "regions",
    DICTIONARY = "dictionary"
}

export function useGetRegions() {
    return useQueryData<string[], Error>(
        [ConfigCacheKeys.REGIONS],
        getRegions,
        true,
        1000 * 60 * 60 * 8 // 8 hours
    )
}

export function useGetDictionaryTerm(
    term: string | undefined,
    enabled: boolean
) {
    return useQueryData<IDictionaryWord | undefined, Error>(
        [ConfigCacheKeys.DICTIONARY, term],
        async () => await getDictionaryTerm(term),
        enabled
    )
}
