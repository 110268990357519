interface INameFragments {
    name: string
    initials: string
    firstName: string
    restOfName: string
}

/**
 * Split a name into fragments
 * @param name Name to split into fragments
 * @returns Object with name, initials, firstName and restOfName
 */
export function nameFragments(name?: string, fallback = ""): INameFragments {
    if (!name) {
        name = fallback
    }

    const nameArray = name.split(" ")
    const initials = nameArray
        .map(n => n[0])
        .slice(0, 2)
        .join("")
    const firstName = nameArray[0]
    const restOfName = nameArray.slice(1).join(" ")

    return {
        name,
        initials,
        firstName,
        restOfName
    }
}
