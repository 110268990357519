import Avatar from "components/UI/Avatar";
import { t } from "locales";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { nameFragments } from "lib/name-fragments";
import { signIn } from "auth/signIn";
import theme from "lib/theme";
export default function ProfileOrganisation({
  name
}: {
  name: string;
}) {
  const {
    initials,
    firstName,
    restOfName
  } = nameFragments(name ?? "");
  return <StyledWrapper data-sentry-element="StyledWrapper" data-sentry-component="ProfileOrganisation" data-sentry-source-file="ProfileOrganisation.tsx">
            <StyledHeaderWrapper data-sentry-element="StyledHeaderWrapper" data-sentry-source-file="ProfileOrganisation.tsx">
                <Avatar color={theme.palette.common.white} background={theme.palette.bhc[800]} data-sentry-element="Avatar" data-sentry-source-file="ProfileOrganisation.tsx">
                    {initials}
                </Avatar>
                <StyledHeaderContent data-sentry-element="StyledHeaderContent" data-sentry-source-file="ProfileOrganisation.tsx">
                    <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="ProfileOrganisation.tsx">
                        {t["profile"]["logged-in-as-organisation"]}
                    </Typography>
                    <StyledName data-sentry-element="StyledName" data-sentry-source-file="ProfileOrganisation.tsx">
                        {firstName}{" "}
                        <StyledRestOfName data-sentry-element="StyledRestOfName" data-sentry-source-file="ProfileOrganisation.tsx">{restOfName}</StyledRestOfName>
                    </StyledName>
                </StyledHeaderContent>
            </StyledHeaderWrapper>

            <StyledSection data-sentry-element="StyledSection" data-sentry-source-file="ProfileOrganisation.tsx">
                <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="ProfileOrganisation.tsx">
                    {t["profile"]["login-with-personal-account"]}
                </Typography>

                <StyledLoginButton variant="outlined" onClick={() => signIn({
        authorization: {
          forceLogin: "true",
          login: "prompt",
          allowIpLogin: "false"
        }
      })} data-sentry-element="StyledLoginButton" data-sentry-source-file="ProfileOrganisation.tsx">
                    {t.login}
                </StyledLoginButton>
            </StyledSection>
        </StyledWrapper>;
}
const StyledWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  gap: theme.spacing(3)
}));
const StyledHeaderWrapper = styled("div")(() => ({
  display: "flex",
  gap: ".75rem",
  padding: theme.spacing(2, 2, 0, 2)
}));
const StyledHeaderContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  flexGrow: 1
}));
const StyledName = styled("div")(() => ({
  gap: "0.5rem",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: 700,
  justifyContent: "center"
}));
const StyledRestOfName = styled("span")(() => ({
  fontWeight: 400
}));
const StyledLoginButton = styled(Button)(() => ({
  alignSelf: "flex-start"
}));
const StyledSection = styled("section")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1)
}));