import { premiumPrefixSlice } from "lib/ssr-path"
import { usePathname, useSearchParams } from "next/navigation"

/** Returns the path, with hash and query
 * slices off ssr prefixes
 * @param includeHasAndQuery Include hash and query in the path
 */
export default function usePath(includeHasAndQuery = false) {
    const pathname = usePathname()
    const searchParams = useSearchParams()
    let asPath = pathname as string
    if (includeHasAndQuery)
        asPath = `${asPath}${searchParams?.toString() ? "?" + searchParams.toString() : ""}`

    asPath = premiumPrefixSlice(asPath)

    return asPath
}
