import { config } from "config"
import useUser from "hooks/useUser"

import {
    INotification,
    getNotifications,
    markNotificationAsRead
} from "data/contentData/api/notification"
import useQueryData from "hooks/useQueryData"
import useMutateData from "hooks/useMutateData"

enum NotificationCacheKeys {
    NOTIFICATIONS = "notifications"
}

export function useGetNotifications() {
    const { authenticated, accessToken, isLoading } = useUser()

    const enabled = config.features.enableNotifications && authenticated && !isLoading

    return useQueryData<INotification[], Error>(
        [NotificationCacheKeys.NOTIFICATIONS],
        async () => await getNotifications(accessToken),
        enabled,
        60 * 60 * 1000,
    )
}

export function useMarkNotificationAsRead() {
    const { accessToken } = useUser()
    const { mutate } = useGetNotifications()

    return useMutateData(
        async ({ id }: { id: number }) =>
            await markNotificationAsRead(id, accessToken),
        () => {
            mutate()
        },
        () => {
            mutate()
        })
}
