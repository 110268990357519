import { Drawer } from "components/Drawer/Drawer";
import { Icon } from "components/Icon";
import Link from "components/Link";
import useUser from "hooks/useUser";
import { t } from "locales";
import { MouseEvent, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FavoriteSorterType } from "data/contentData/api/user";
import { useFavorites } from "data/contentData/hooks/user.hooks";
import { toLocalDate } from "lib/string/date-to-locale-date-string";
export function Favorites() {
  const {
    authenticated
  } = useUser();
  const {
    favorites,
    remove,
    sort,
    sortBy
  } = useFavorites();
  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const [sortState, setSortState] = useState<string>(sortBy);
  useEffect(() => {
    setSortState(sortBy);
  }, [sortBy]);
  const handleSort = (_event: MouseEvent<HTMLElement>, value: FavoriteSorterType | null) => {
    if (value) {
      setSortState(value);
      sort(value);
    }
  };
  if (!authenticated) {
    return null;
  }
  return <>
            <Tooltip title={t["favorite"]["favorites"]} data-sentry-element="Tooltip" data-sentry-source-file="Favorites.tsx">
                <IconButton color="inherit" ref={anchorEl} aria-label={t["favorite"]["favorites"]} onClick={handleOpen} size="large" data-sentry-element="IconButton" data-sentry-source-file="Favorites.tsx">
                    <Icon name="favorite" data-sentry-element="Icon" data-sentry-source-file="Favorites.tsx" />
                </IconButton>
            </Tooltip>

            <Drawer header={<Typography align="center" variant="subtitle2">
                        {t["favorite"]["favorites"]}
                    </Typography>} position="right" open={open} onClose={handleClose} data-sentry-element="Drawer" data-sentry-source-file="Favorites.tsx">
                {favorites?.length ? <>
                        <Grid gap={1} padding={2} container alignItems="center" justifyContent="flex-start">
                            <Typography variant="body2">
                                {t["favorite"]["sort-by"]}
                            </Typography>
                            <StyledToggleButtonGroup aria-label={t["favorite"]["sort"]["options"]} exclusive size="small" value={sortState} onChange={handleSort}>
                                <ToggleButton value="abc" aria-label={t["favorite"]["sort"]["abc"]}>
                                    <Icon name="abcSort" fontSize="small" />
                                    {t["favorite"]["sort"]["abc"]}
                                </ToggleButton>
                                <ToggleButton value="time" aria-label={t["favorite"]["sort"]["time"]}>
                                    <Icon name="timeSort" fontSize="small" />
                                    {t["favorite"]["sort"]["time"]}
                                </ToggleButton>

                                <ToggleButton value="template" aria-label={t["favorite"]["sort"]["template"]}>
                                    <Icon name="categorySort" fontSize="small" />
                                    {t["favorite"]["sort"]["template"]}
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                        </Grid>
                        <List disablePadding>
                            {favorites.map(favorite => <MenuItem key={favorite.url} onClick={handleClose} tabIndex={0}>
                                    <ListItemText primary={<Link href={favorite.url}>
                                                {favorite.name}
                                            </Link>} primaryTypographyProps={{
              noWrap: true
            }} secondary={<>
                                                <Typography color="textPrimary" component="span" variant="caption">
                                                    {favorite.template}
                                                </Typography>
                                                <Typography variant="caption">
                                                    {" — "}
                                                    {favorite.timestamp ? toLocalDate(favorite.timestamp, process.env.NEXT_PUBLIC_LOCALE) : null}
                                                </Typography>
                                            </>} />
                                    <ListItemSecondaryAction>
                                        <Tooltip title={t["favorite"]["remove-from-favorites"]}>
                                            <IconButton aria-label={t["favorite"]["remove-from-favorites"]} edge="end" onClick={e => {
                  e.stopPropagation();
                  remove(favorite.url);
                }} size="large">
                                                <Icon name="delete" color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </MenuItem>)}
                        </List>
                    </> : <Grid height={"100%"} my={6} container justifyContent="center">
                        <Typography variant="caption">
                            {t["favorite"]["none"]}
                        </Typography>
                    </Grid>}
            </Drawer>
        </>;
}
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({
  theme
}) => ({
  "& .MuiToggleButton-root": {
    textTransform: "capitalize",
    gap: theme.spacing(0.5)
  }
}));