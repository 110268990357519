import { HttpError } from "lib/http/http"
import { useCallback, useState } from "react"

function useMutateData<T, P extends any[]>(mutator: (...params: P) => Promise<T>, onSuccessful?: () => void, onError?: () => void) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [data, setData] = useState<T | null>(null)

    const mutate = useCallback(async (...params: P) => {
        setIsLoading(true)
        setError(null)

        try {
            const result = await mutator(...params)
            setData(result)
            if (onSuccessful) onSuccessful()
        } catch (err) {
            setError(err as HttpError)
            if (onError) onError()
        } finally {
            setIsLoading(false)
        }
    }, [mutator, onError, onSuccessful])

    return { mutate, isLoading, error, data }
}

export default useMutateData
