import { Image } from "components/Image";
import Link from "components/Link";
import { t } from "locales";
import { styled } from "@mui/material/styles";
const src = `/${process.env.NEXT_PUBLIC_ID}/logo.svg`;
const logo = {
  localhost: {
    height: 25,
    width: 65
  },
  "legehandboka-dev": {
    height: 25,
    width: 65
  },
  "legehandboka-no": {
    height: 25,
    width: 65
  },
  "nevrologi-legehandboka-no": {
    height: 25,
    width: 165
  },
  "deximed-de": {
    height: 42,
    width: 175
  },
  "medibas-se": {
    height: 42,
    width: 175
  },
  "medibas-pl": {
    height: 25,
    width: 163
  },
  "medibas-fr": {
    mobile: `/${process.env.NEXT_PUBLIC_ID}/logo_mobile.svg`,
    mobileHeight: 25,
    mobileWidth: 93,
    height: 42,
    width: 156
  }
}[process.env.NEXT_PUBLIC_ID];
export default function Logo(props: {
  simple?: boolean;
}) {
  const Component = props.simple ? "a" : Link;
  return <Component color="inherit" href="/" data-sentry-element="Component" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
            <Picture data-sentry-element="Picture" data-sentry-source-file="Logo.tsx">
                <source srcSet={logo.mobile ?? src} media="(max-width: 960px)" />
                <source srcSet={src} media="(min-width: 961px)" />
                <LogoImage priority src={src} alt={t.header["logo-alt"]} width={logo.mobileWidth ?? logo.width} height={logo.mobileHeight ?? logo.height} data-sentry-element="LogoImage" data-sentry-source-file="Logo.tsx" />
            </Picture>
        </Component>;
}
const Picture = styled("picture")(() => ({
  display: "inline-flex",
  padding: 8
}));
const LogoImage = styled(Image)(({
  theme
}) => ({
  display: "flex",
  height: logo.mobileHeight ?? logo.height,
  width: logo.mobileWidth ?? logo.width,
  [theme.breakpoints.up("md")]: {
    height: logo.height,
    width: logo.width
  }
}));