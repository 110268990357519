import { styled } from "@mui/material/styles";
import theme from "lib/theme";
import { JSX } from "react";
interface IProps {
  children: number | string | JSX.Element;
  title?: string;
  color?: string;
  background?: string;
}
const defaultColor = theme.palette.text.default;
const defaultBackground = theme.palette.badge;
export function Badge({
  children,
  title,
  color = defaultColor,
  background = defaultBackground
}: IProps) {
  return <StyledBadge color={color} background={background} title={title} data-sentry-element="StyledBadge" data-sentry-component="Badge" data-sentry-source-file="Badge.tsx">
            {children}
        </StyledBadge>;
}
type BadgeProps = {
  color: string;
  background: string;
};
const StyledBadge = styled("span")<BadgeProps>(({
  color,
  background
}) => ({
  color: color,
  backgroundColor: background,
  borderRadius: "2px",
  marginLeft: theme.spacing(1),
  padding: "4px 6px",
  fontSize: 12,
  lineHeight: "normal"
}));