import { DAYS_BETWEEN_CONFIRM_USER_DETAILS } from "components/ConfirmUserDetails/ConfirmUserDetails";
import HPRField from "components/ConfirmUserDetails/HPRField";
import SelectProfessionFields from "components/ConfirmUserDetails/SelectProfessionFields";
import WorkPlaceField from "components/ConfirmUserDetails/WorkPlaceField";
import { formatISO, subDays } from "date-fns";
import { t } from "locales";
import { FormEvent } from "react";
import { ChangeEvent, useState } from "react";
import Button from "@mui/material/Button";
import { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { IProfile, ProfileFormType } from "data/customerManager/api/profile";
import { useUpdateProfile } from "data/customerManager/hooks/profile.hooks";
import useUser from "hooks/useUser";
import { snackbarMessage } from "components/Snackbar/utils";
interface IProps {
  profile: IProfile;
  handleClose: () => void;
}
type ChangeEventType = ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent;
export default function ConfirmUserDetailsForm({
  profile,
  handleClose
}: IProps) {
  const {
    professions,
    ...initialForm
  } = profile;
  const [form, setForm] = useState<ProfileFormType>(initialForm);
  const handleChange = (e: ChangeEventType) => {
    if (e.target.name === "professionId") {
      setForm({
        ...form,
        professionId: parseInt(e.target.value),
        secondaryProfessionId: 0,
        tertiaryProfessionId: 0,
        placeOfWork: ""
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      });
    }
  };
  const {
    mutate
  } = useUpdateProfile();
  const {
    accessToken
  } = useUser();
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const _form = {
      ...form,
      lastProfileUpdate: null
    };
    mutate(_form, accessToken);
    snackbarMessage({
      open: true,
      message: t["user-details"]["success"],
      color: "info"
    });
    handleClose();
  };
  const handlePostpone = async () => {
    const postponeDate = formatISO(subDays(new Date(), DAYS_BETWEEN_CONFIRM_USER_DETAILS - 7));
    const _form = {
      ...form,
      lastProfileUpdate: postponeDate
    };
    mutate(_form, accessToken);
    handleClose();
  };
  return <StyledForm onSubmit={handleSubmit} data-sentry-element="StyledForm" data-sentry-component="ConfirmUserDetailsForm" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
            <Typography pt={2} data-sentry-element="Typography" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
                {t["user-details"]["fill-and-confirm"]}
            </Typography>

            <StyledReadOnlyWrapper data-sentry-element="StyledReadOnlyWrapper" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
                <div>
                    <Typography fontWeight={700} data-sentry-element="Typography" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
                        {t["user-details"]["name"]}
                    </Typography>
                    <Typography data-sentry-element="Typography" data-sentry-source-file="ConfirmUserDetailsForm.tsx">{profile?.name}</Typography>
                </div>

                <div>
                    <Typography fontWeight={700} data-sentry-element="Typography" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
                        {t["user-details"]["email"]}
                    </Typography>
                    <Typography data-sentry-element="Typography" data-sentry-source-file="ConfirmUserDetailsForm.tsx">{profile?.email}</Typography>
                </div>
            </StyledReadOnlyWrapper>

            <TextField fullWidth id="postAddress" label={t["user-details"]["address"]} name="postAddress" onChange={handleChange} value={form.postAddress} variant="outlined" required data-sentry-element="TextField" data-sentry-source-file="ConfirmUserDetailsForm.tsx" />
            <StyledPostalWrapper data-sentry-element="StyledPostalWrapper" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
                <TextField fullWidth id="zip" label={t["user-details"]["zip"]} name="zip" onChange={handleChange} value={form.zip} variant="outlined" inputProps={{
        minLength: 4
      }} required data-sentry-element="TextField" data-sentry-source-file="ConfirmUserDetailsForm.tsx" />
                <TextField fullWidth id="city" label={t["user-details"]["city"]} name="city" onChange={handleChange} value={form.city} variant="outlined" required data-sentry-element="TextField" data-sentry-source-file="ConfirmUserDetailsForm.tsx" />
            </StyledPostalWrapper>

            <SelectProfessionFields form={form} professions={professions} onChange={handleChange} data-sentry-element="SelectProfessionFields" data-sentry-source-file="ConfirmUserDetailsForm.tsx" />

            <HPRField form={form} onChange={handleChange} data-sentry-element="HPRField" data-sentry-source-file="ConfirmUserDetailsForm.tsx" />
            <WorkPlaceField form={form} onChange={handleChange} data-sentry-element="WorkPlaceField" data-sentry-source-file="ConfirmUserDetailsForm.tsx" />

            <Typography data-sentry-element="Typography" data-sentry-source-file="ConfirmUserDetailsForm.tsx">{t["user-details"]["remind-me-later-text"]}</Typography>

            <StyledButtonWrapper data-sentry-element="StyledButtonWrapper" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
                <Button variant="outlined" onClick={handlePostpone} data-sentry-element="Button" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
                    {t["user-details"]["remind-me-later"]}
                </Button>
                <Button type="submit" variant="filled" data-sentry-element="Button" data-sentry-source-file="ConfirmUserDetailsForm.tsx">
                    {t["user-details"]["confirm-button"]}
                </Button>
            </StyledButtonWrapper>
        </StyledForm>;
}
const StyledReadOnlyWrapper = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "1rem",
  marginTop: ".5rem",
  marginBottom: ".5rem"
}));
const StyledPostalWrapper = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 2fr",
  gap: "1rem"
}));
const StyledButtonWrapper = styled("div")(({
  theme
}) => ({
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "flex-end"
}));
const StyledForm = styled("form")(({
  theme
}) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2)
}));