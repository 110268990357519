import { t } from "locales";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useGetUserSettings, useUpdateUserSettings } from "data/contentData/hooks/user.hooks";
import useUser from "hooks/useUser";
export function DictionarySwitch() {
  const {
    data: userSettings
  } = useGetUserSettings();
  const {
    mutate,
    isLoading
  } = useUpdateUserSettings();
  const {
    accessToken
  } = useUser();
  const showMark = userSettings?.markWordsInDictionary ?? false;
  const handleChange = () => {
    mutate({
      ...userSettings,
      markWordsInDictionary: !showMark
    }, accessToken);
  };
  return <StyledWrapper data-sentry-element="StyledWrapper" data-sentry-component="DictionarySwitch" data-sentry-source-file="DictionarySwitch.tsx">
            <FormControlLabel control={<Switch checked={showMark} onChange={handleChange} />} disabled={isLoading} label={t["profile"]["dictionary"]["toggle-mark-words"]} data-sentry-element="FormControlLabel" data-sentry-source-file="DictionarySwitch.tsx" />
            <Typography variant="caption" data-sentry-element="Typography" data-sentry-source-file="DictionarySwitch.tsx">
                {t.profile.dictionary.description}
            </Typography>
        </StyledWrapper>;
}
const StyledWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column"
}));