import { config } from "config"
import { get } from "lib/http/http"

const { general } = config

export interface ISearchFacet {
    order: number
    label: string
    count: number
    icon: string
    viewType: string
}

interface ISearchSection {
    headerShortcutName: string
    headerName: string
}

export interface ISearchHit {
    score: number
    title: string
    url: string
    topic: string
    template: string
    templateSubGroup: string
    templateSubGroupIcon: string
    created: Date
    published?: Date
    unpublished?: Date
    updated?: Date
    revised?: Date
    sectionShortcuts: ISearchSection[]
    isPublic: boolean
}

export interface ISearchResult {
    facets: ISearchFacet[]
    hits: ISearchHit[]
    fuzzy: boolean
}

/**
 * Search for documents on query
 * @param q Query to search for
 * @returns ISearchResult containing facets and hits
 */
export async function getSearch(
    q?: string | null | undefined,
    templateSubGroup: string | null = null,
    limit: number = 100,
    skip: number = 0,
    sortType: number = 0,
    fuzzy: boolean = true,
    editorMode: boolean = false,
    bypassCache: boolean = false
): Promise<ISearchResult> {
    const emptyResult = {
        facets: [],
        hits: [],
        fuzzy: false
    } as ISearchResult

    if (!q) {
        return emptyResult
    }

    const query = Array.isArray(q) ? q.join(" ") : q
    const endpoint =
        `${process.env.NEXT_PUBLIC_SEARCHMANAGER_API}/${general.searchManagerConfig}/search` +
        `?query=${encodeURIComponent(query)}` +
        `&templateSubGroup=${encodeURIComponent(templateSubGroup ?? "")}` +
        `&limit=${limit}` +
        `&skip=${skip}` +
        `&sortType=${sortType}` +
        `&fuzzy=${fuzzy}` +
        `&editorMode=${editorMode}` +
        `&bypassCache=${bypassCache}`

    const { data } = await get<ISearchResult>(endpoint)
    return data ?? emptyResult
}

export interface ILatestArticle {
    timestamp: Date
    title: string
    url: string
}

/**
 * Get list of new, updated and revised articles
 * @param templateGroup Feks. "Profesjonelle"
 * @param limit number of articles to get
 * @param includeNew yup
 * @param includeUpdated you know
 * @param includeRevised this
 * @returns List of ILatestArticle
 */
export async function getLatestArticles(
    templateGroup: string,
    limit: number,
    includeNew: boolean,
    includeUpdated: boolean,
    includeRevised: boolean,
    bypassCache?: boolean
): Promise<ILatestArticle[]> {
    let params = `limit=${limit}&templateGroup=${encodeURIComponent(
        templateGroup
    )}&includeNew=${includeNew}&includeUpdated=${includeUpdated}&includeRevised=${includeRevised}`

    if (bypassCache) {
        params += `&bypassCache=${bypassCache}`
    }

    const endpoint =
        `${process.env.NEXT_PUBLIC_SEARCHMANAGER_API}/${general.searchManagerConfig}` +
        "/articles/latest?" +
        params

    const { data } = await get<ILatestArticle[]>(endpoint)
    return data ?? []
}

interface ISearchSuggestion {
    url: string
    name: string
    topic: string
}

export interface ISearchSuggestionGroup {
    icon: string
    name: string
    suggestions: ISearchSuggestion[]
}

/**
 * Get a list of search suggestions
 * @param q Query
 * @param limit Number of suggestions to get
 * @returns List of ISearchSuggestionGroup
 */
export async function getSearchSuggestions(q: string, limit: number = 5) {
    const endpoint =
        `${process.env.NEXT_PUBLIC_SEARCHMANAGER_API}/${general.searchManagerConfig}` +
        "/suggestion?query=" +
        encodeURIComponent(q) +
        `&limit=${limit}`
    const { data } = await get<ISearchSuggestionGroup[]>(endpoint)
    return data ?? []
}

/**
 * Get a list of autocomplete "suggestions"
 * @param q Query
 * @param limit Number of autocompletes to get
 * @returns List of string
 */
export async function getSearchAutocomplete(q: string, limit: number = 5) {
    const endpoint =
        `${process.env.NEXT_PUBLIC_SEARCHMANAGER_API}/${general.searchManagerConfig}` +
        "/autocomplete?query=" +
        encodeURIComponent(q) +
        `&limit=${limit}`
    const { data } = await get<string[]>(endpoint)
    return data ?? []
}
